<template>
  <div id="videoPage" >
    <transition name="topNav">
      <!---->
      <van-nav-bar v-if="navOpt.show || menuShow" @click-left="$router.back()" @click-right="setNavShow();menuShow = !menuShow">
        <i class="icon back-arrow" slot="left"></i>
        <div class="title" slot="title">{{ detail.name }}</div>
        <div class="right" slot="right">
          <i class="icon icon-menu"></i>
        </div>
      </van-nav-bar>
      <!--<div class="top-nav-bar">-->
        <!---->
      <!--</div>-->
    </transition>
    <div class="container iphoneX">
      <!-- <iframe
        v-if="detail && detail.id"
        ref="videoBox"
        class="video-box"
        :src="`./static/pages/vrVideo.html`"
        frameborder="0"
        @load="ininIframe"
        :key="detail.videoUrl"
      ></iframe> -->
      <VRVideo
        v-if="detail.videoUrl"
        :src="detail.videoUrl || ''"
        @init="init"
        :key="detail.videoUrl"
        :playCfg="{isHemisphere:isPart}"
      >
        <template slot="other">
          <div id="selectResolve" v-if="show" @click.stop.prevent="show = false">
            <div class="selectResolve-box">
              <div class="title">
                画質
              </div>
              <ul class="list">
                <li class="list-item"
                    @click.stop.prevent="change(index)"
                    :class="{'active': currentIndex === index }"
                    v-for="(item,index) in resolveSelect"
                    :key="item.id"
                >
                  <div class="list-title">{{ item.text }}</div>
                  <div class="list-content">{{ item.account }}</div>
                  <p v-if="index ===0"/>
                  <van-image v-else-if="index ===1" class="img" :src="require('@/assets/images/icon_tag_08.png')" />
                  <van-image v-else-if="index ===2" class="img" :src="require('@/assets/images/icon_tag_06.png')" />
                </li>
              </ul>
            </div>
          </div>
          <transition name="fade">
            <div v-if="pageTip.show1" class="page-tip page-tip-1">
              <ul class="box">
                <div class="box-item box-item-1">
                  <i class="icon icon-tag icon-1"></i>
                  <i class="icon icon-tag icon-2"></i>
                  <i class="icon icon-tag icon-2"></i>
                </div>
                <div class="box-item">画質は会員のランクによって違います</div>
                <div class="box-item box-item-1">
                  <i class="icon icon-vr"></i>
                </div>
                <div class="box-item">２DとVRモードを切り替え</div>
                <div class="box-item box-item-1">
                  <i class="icon icon-hand"></i>
                </div>
                <div class="box-item">指コントルールとジャイロを切り替え</div>
              </ul>
              <button type="button" class="btn" @click="alreadyViews">了解</button>
            </div>
            <div v-if="pageTip.show2" class="page-tip page-tip-2">
              <template v-if="isGyro">
                <i class="icon icon-operate-2"></i>
                <div class="name">ジャイロモード</div>
              </template>
              <template v-else>
                <i class="icon icon-operate-1"></i>
                <div class="name">指コントルールモード</div>
              </template>
            </div>
          </transition>
          <!-- 分辨率需要购买的弹层开始 -->
          <van-popup
            class="popup popup-showPlay"
            v-model="showPlay"
            closeable
            close-icon="close"
            :close-on-click-overlay="false"
            @click-close-icon="closeIcon"
          >
            <template v-if="selectIndex===0">
              <van-image
                :src="require('@/assets/images/bg_podium_04.png')"
                fit="cover"
                class="img"
              ></van-image>
              <div class="content">
                <div class="title">SD動画を購入</div>
                <div class="info">ベーシク会員になる（SD動画が見放題）</div>
              </div>
            </template>
            <template v-else-if="selectIndex===1">
              <van-image
                :src="require('@/assets/images/bg_podium_01.png')"
                fit="cover"
                class="img"
                v-if="!personInfo.jurisdiction.includes(4) && detail.type=== 3"
              ></van-image>
              <van-image
                :src="require('@/assets/images/bg_podium_02.png')"
                fit="cover"
                class="img"
                v-else
              ></van-image>
              <div class="content">
                <div class="title">HD動画を購入</div>
                <template v-if="!personInfo.jurisdiction.includes(4) && detail.type=== 3">
                  <div class="info">プレミアム会員になる（すべての作品が見放題）</div>
                  <van-button class="btn btn01" @click="$router.push({name:'MemberIndex'})">プレミアム会員になる</van-button>
                </template>
                <template v-else>
                  <div class="info">スタンダード会員になる（SD動画とHD動画が見放題）</div>
                  <van-button class="btn btn01" @click="$router.push({name:'MemberIndex'})">スタンダード会員になる</van-button>
                </template>
              </div>
            </template>
            <template v-else-if="selectIndex===2">
              <van-image
                :src="require('@/assets/images/bg_podium_01.png')"
                fit="cover"
                class="img"
              ></van-image>
              <div class="content">
                <div class="title">４K動画を購入</div>
                <div class="info">プレミアム会員になる（すべての作品が見放題）</div>
                <van-button class="btn btn01" @click="$router.push({name:'MemberIndex'})">プレミアム会員になる</van-button>
              </div>
            </template>
            <div class="content">
              <van-button class="btn btn02" @click="toBuy(watchDetail.id)">{{ pay }}円で購入</van-button>
            </div>
          </van-popup>
          <!-- 分辨率需要购买的弹层结束 -->
          <!-- 分享与稍后播放弹层 -->
          <PopupShow
            :detail="actions"
            @later="later"
            :showFun="showFun"
            @upDate="upDate"
            :isRecommend="isRecommend"
          >
          </PopupShow>
        </template>
      </VRVideo>
      <transition name="menuList">
        <ul v-if="menuShow" class="menu-list">
          <router-link
            class="list-item"
            :to="{name:'Video', query: { id: item.id }}"
            replace
            v-for="(item, index) in list"
            :key="index"
            :class="[style[item.type]]"
            tag="li"
          >
            <div class="thumb">
              <van-image class="img" :src="item.coverAcrossTemp" fit="cover"></van-image>
              <div class="mask">
                <div class="item item-1">
                  <div class="left">
                    <i class="icon icon-tag"></i>
                  </div>
                  <div class="right" @click.stop.prevent="detailShowFun(item)">
                    <i class="icon icon-more" v-if="item.id !== parseInt(id)"></i>
                  </div>
                </div>
                <div class="item item-2">
                  <div class="left">
                    <i class="icon icon-eyes"></i>
                    <div class="name">{{ item.sumNumber }}</div>
                  </div>
                  <div class="right">{{ item.time }}</div>
                </div>
                <i class="icon icon-play"></i>
              </div>
            </div>
            <div class="content">
              <div class="name">{{item.name}}</div>
            </div>
          </router-link>
        </ul>
      </transition>
    </div>
  </div>
</template>
<script language="javascript" type="text/javascript" src="/player/plugin/gyro/gyroHelp.js"></script>
<script>
import { NavBar } from 'vant';
import { PopupShow, VRVideo } from '@/components'
import { mapGetters } from 'vuex'
export default {
  name: 'videoPage',
  components: {
    'van-nav-bar': NavBar,
    PopupShow,
    VRVideo
  },
  data () {
    return {
      vr:{},
      id: '',
      detail: {
        videoUrl: ''
      },
      list:[],
      newList:[],
      // list3: [{
      //   url: './static/images/img_thumb_02.jpg',
      //   name: 'BGS限定  「東京ガールズコレクション」VR映像',
      //   type: 1,
      //   id: 5,
      //   videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/TGC~1920i.mp4'
      // }, {
      //   url: './static/images/img_thumb_14.jpg',
      //   name: '人気コスプレイヤー  「五木あきら」撮影風景',
      //   type: 1,
      //   id: 3,
      //   videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/itsuki%20akira_posing~1920i.mp4'
      // }, {
      //   url: './static/images/img_thumb_15.jpg',
      //   name: 'BGS限定  「デヴィ夫人コーポレートアンバサダー」記念コンテンツ',
      //   type: 1,
      //   id: 4,
      //   videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/msdeviVR0204~1920i.mp4'
      // }, {
      //   url: './static/images/img_thumb_16.jpg',
      //   name: '美女モデルでコスプレイヤー「深海永理」',
      //   type: 2,
      //   id: 1,
      //   videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/shinkai%20eri%20main%20ver1~1920i.mp4'
      // }, {
      //   url: './static/images/img_thumb_17.jpg',
      //   name: '10（ヒトマル）式戦車  VR',
      //   type: 2,
      //   id: 2,
      //   videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/tank~1920i.mp4'
      // }, {
      //   url: './static/images/img_thumb_18.jpg',
      //   name: '天才コスプレイヤー「東堂とも」',
      //   type: 1,
      //   id: 10,
      //   videoUrl: 'https://vravo-1258661974.cos.accelerate.myqcloud.com/todo%20tomo_main%20ver1~1920i.mp4'
      // }],
      iframe: {},
      navOpt: {
        show: false,
        timer: null
      },
      menuShow: false,
      style: {
        3: 'style-1', // 4k
        2: 'style-2' // vip
      },
      bgStyle:{
        1: 'bgStyle-1',
        2: 'bgStyle-2',
        3: 'bgStyle-3'
      },
      pageTip : {
        show1: false,
        show2: false
      },
      isGyro: true,
      handShowTimer: null,
      showFun: false, // 稍后观看与分享的弹层
      show: false, //分辨率的弹窗
      actions: {},
      pay:'', //视频清晰度价格
      watchDetail:{}, // 当前视频的详情
      resolveSelect:[
        { id:1,text:'720P', account:'普通',img:'' },
        { id:2,text:'1080P', account:'HD',img:'src/assets/images/icon_tag_08.png' },
        { id:3,text:'4k', account:'4k',img:'~@/assets/images/icon_tag_06.png' }
      ],
      currentIndex:0, // 记录当前选中的清晰度项
      showPlay:false, // 控制分辨率需要支付弹层的显隐
      selectIndex:0, // 当不满足权限条件时选中的项
      isPart:true, // 是否是半球
      isRecommend:false // ture 表示推荐的视频  false表示不是推荐的视频
    };
  },
  async mounted () {
    this.pageTip.show1 = !localStorage.getItem('isFirstViews');
    await this.getLaterDetail()
    // this.orientTip();
    // window.addEventListener("message", function(e){
    //   console.log(e.data);
    // }, false);
  },
  computed:{
    ...mapGetters(['personInfo'])
  },
  watch:{
    currentIndex(newValue){
      this.vr.toolBar.claritTyext.innerText = this.resolveSelect[newValue].text;
      console.log(this.detail.videoUrl);
    },
    '$route.query':{
      async handler (newVal) {
        const { $route } = this;
        this.currentIndex = 0;
        const { id } = newVal;
        this.id = id;
        await this.getVideoDetail();
        const {name} = this.detail;
        $route.meta.title = name;
        document.title = name;
        this.getDetail();
        this.videoHistory();

      },
      immediate: true
    }
  },
  methods: {
    init (vr) {
      const elem = document.createElement('div');
      elem.innerText = this.resolveSelect[this.currentIndex].text;
      vr.toolBar.rightBox.insertBefore(elem, vr.toolBar.gyroResetBtn);
      vr.toolBar.claritTyext = elem;
      vr.toolBar.claritTyext.addEventListener("click",() => {
        this.show = !this.show;
      })
      this.setNavShow();
      vr.container.addEventListener("click", () => {
        this.setNavShow();
      }, false);

      this.vr = vr
    },

    /* 点击观看视频时获取视频信息 */
    getDetail () {
      this.$http.get('video/watchVideo',{
      params:{
        videoId:this.id
      }
      }).then(res => {
        this.watchDetail = res.data;
        /* 页面默认加载720P视频的路径 */
        if(this.watchDetail.standardJurisdiction === 1){
          this.detail.videoUrl = this.watchDetail.standardVideoTemp;
          this.currentIndex = 0
        }else if(this.watchDetail.highJurisdiction === 1){
          this.detail.videoUrl = this.watchDetail.highVideoTemp;
          this.currentIndex = 1
        }else if(this.watchDetail.exceedJurisdiction === 1){
          this.detail.videoUrl = this.watchDetail.exceedVideoTemp;
          this.currentIndex = 2
        }

        // this.detail.videoUrl = 'https://vravo-1258661974.cos.accelerate.myqcloud.com/TGC~1920i.mp4'
                                  //https://vravo-1258661974.cos.accelerate.myqcloud.com/video/6666666_1636361706748_720p.mp4

      });
    },
    /* 获取稍后播放列表 */
    getLaterDetail () {
      return this.$http.get('video/laterList').then(res => {
        const data = res.data || {};
        this.list = data.list;
        this.isRecommend = data.isRecommend
      })
    },
    /* 获取视频详情页的接口数据 */
    async getVideoDetail () {
      let { id, list, $http, $utils } = this;
      await $http.get('video/videoDetail', {
        params: {
          videoId: id
        }
      }).then(async (res) => {
        const data = res.data || {};
        this.isPart = data.formatType === 1 ?  true : false// true:180  false:360
        if(data.type !== 3){
          this.resolveSelect.pop()
        }

        if(data.type===1 || $utils.getToken() || data.putaway === 2){
          // delete data.list;
          // 如果是没有添加到稍后播放的列表的话
          // if(!list.find(item => { return item.id === id*1; })){

          // await $http.get('video/operationLater',{
          //     params:{
          //       videoId:id
          //     }
          //   }).then(res => {
          //     data.whetherLater = 1;
          //   })
          //   list.unshift(data);
          // }
          this.detail = {videoUrl: '',...data};
        }else{
          $utils.toast({
            message: 'ログインへ',
            onClose: () => {
              this.$router.push({
                name: 'Login',
                params: {
                  type: 1
                }
              });
            }
          });
        }

      });

    },
    /* 添加到观看记录 */
    videoHistory () {
      if(this.id){
        this.$http.get('video/addRecord',{
          params:{
            videoId:this.id
          }
        }).then(res => {});
      }
    },
    /* 添加或移除稍后观看视频 */
    later () {
      this.getLaterDetail();
    },

    /* 详情分类分享 */
    detailShowFun (item) {
      const { $utils, $router } = this;
      if ($utils.getToken()) {
        this.showFun = !this.showFun;
        console.log(item);
        const { id, whetherLater=1, putaway, type, whetherBuy,name } = item;
        this.actions = { id, whetherLater, putaway, type, whetherBuy,name };
      } else {
        $utils.toast({
          message: 'ログインへ',
          onClose: () => {
            $router.push({
              name: 'Login',
              params: {
                type: 1
              }
            });
          }
        });
      }

    },

    /* 修改稍后播放与分享弹层的显影状态 */
    upDate (flag) {
      this.showFun = flag;
    },

    /* 点击切换分辨率 */
    change (index) {
      let { resolveSelect, watchDetail } = this;
      const item = resolveSelect[index];
      const name = {1: 'standard',2: 'high',3: 'exceed'}[item.id];
      if(watchDetail[`${name}Jurisdiction`] === 1){
        this.$http.get(`/video/${name}Record`,{
          params:{
            videoId:this.id
          }
        }).then(res => {
          // watchDetail.highVideo === "1"
          // watchDetail.standardVideo === "2"
          this.detail.videoUrl = watchDetail[`${name}VideoTemp`];
          console.log(this.detail.videoUrl);
          this.currentIndex = index
          this.show = false;
        });
      }else{
        this.selectIndex = index
        this.pay = watchDetail[`${name}Number`]
        this.show = false
        this.showPlay = true;
      }
    },

    /* 购买视频 */
    toBuy (id) {
      let select = 0
      const { selectIndex } = this
      // console.log(this.currentIndex);
      if(selectIndex === 1){
        select = 2;
      }
      if(selectIndex === 2){
        select = 3;
      }
      this.$router.push({
        name: 'VideoBuy',
        query: {
          type: select,
          videoId: id,
          payMent: this.pay
        }
      });
    },
    setNavShow () {
      const { navOpt } = this;
      clearTimeout(navOpt.timer);
      navOpt.show = true;
      navOpt.timer = setTimeout(() => {
        navOpt.show = false
      }, 3000)

    },
    /* 关闭图标 看需求是否需要 */
    closeIcon(){
      this.show = false;
    },

    ininIframe () {
      const { iframe, setNavShow, setHandShow, detail:{name,videoUrl}, resolveSelect, currentIndex } = this;
      const iframeEl = iframe.el = this.$refs.videoBox;
      const content = iframe.content = iframeEl.contentWindow;
      console.log(content);
      content.initLoad({
        container: content.videoBox,
        name: 'SceneViewer',
        isGyro: true, // 默认开启陀螺仪功能  移动端支持陀螺仪设备有效
        scenesArr: [{
          sceneId: 'v1',
          sceneName: name || '',
          sceneFilePath: videoUrl || '',
          sceneType: 'Video',
          isVideoAutoPlay: false
        }],
        fullScreenMode: true,
        sceneEventClickCallBack: () => {
          setNavShow();
          content.setToolbarShow();
          this.menuShow = false
        },
        initOverCallBack () {
          iframe.playerObj = this; // eslint-disable-line
          console.log(this);
        },
        loadedStartCallBack  () {
          content.getVideoToolbar();
          const gyroElem = content.setGyroElem()
          content.setResolveElem(resolveSelect[currentIndex].text);
          if(gyroElem){
            gyroElem.on('click', function () {
              iframe.playerObj.api_ToggleGyro();
              setHandShow(iframe.playerObj.api_getGyroStatus());
            });
            content.setGyroToggle(iframe.playerObj.api_getGyroStatus());
          }
          setNavShow();
          content.setToolbarShow();
        },
        fullScreenChangeCallBack(){

        },
        // 播放器不支持全景播放回调
        errorCallBack (e) {
          console.log('错误状态：' + e);
        },
        // 浏览器不支持全屏回调
        fsCallBack (status, playObj) {
          console.log(status, playObj)
          alert('ブラウザはフルスクリーンをサポートしていません。');
        }
      });
      // /* 控制分辨率 */
      // content.showResolve = ()=>{
      //   this.showResolve()
      // },

      console.log(content.initLoad);

    },

    setHandShow(isGyro) {
      const { handShowTimer, pageTip, iframe: { content } } = this;
      content.setGyroToggle(!isGyro);
      this.isGyro = isGyro;
      clearTimeout(handShowTimer);
      pageTip.show2 = true;
      this.handShowTimer = setTimeout(() => {
        pageTip.show2 = false
      }, 2000)
    },
    async createXRButton (renderer) {

      function showEnterVR (/* device*/) {

        let currentSession = null;

        async function onSessionStarted (session) {

          session.addEventListener('end', onSessionEnded);

          await renderer.xr.setSession(session);
          currentSession = session;

        }

        function onSessionEnded (/* event*/) {

          currentSession.removeEventListener('end', onSessionEnded);
          currentSession = null;

        }

        return function () {

          if (currentSession === null) {

            // WebXR's requestReferenceSpace only works if the corresponding feature
            // was requested at session creation time. For simplicity, just ask for
            // the interesting ones as optional features, but be aware that the
            // requestReferenceSpace call will fail if it turns out to be unavailable.
            // ('local' is always available for immersive sessions and doesn't need to
            // be requested separately.)

            const sessionInit = { optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking', 'layers'] };
            navigator.xr.requestSession('immersive-vr', sessionInit).then(onSessionStarted);

          } else {

            currentSession.end();

          }

        };

      }


      function showWebXRNotFound () {
        alert('VR NOT SUPPORTED');
      }
      let supported = false
      if ('xr' in navigator) {

        supported = await navigator.xr.isSessionSupported('immersive-vr');

      }
      return supported ? showEnterVR() : showWebXRNotFound;
      // const message = document.createElement('a');
      //
      // if (window.isSecureContext === false) {
      //
      //   message.href = document.location.href.replace(/^http:/, 'https:');
      //   message.innerHTML = 'WEBXR NEEDS HTTPS'; // TODO Improve message
      //
      // } else {
      //
      //   message.href = 'https://immersiveweb.dev/';
      //   message.innerHTML = 'WEBXR NOT AVAILABLE';
      //
      // }
      //
      // return message;


    },
    orientTip () {
      const { $utils: { toast } } = this;
      if(this.getOrient){
        toast({
          message: '横画面モードをオンにして、より効果的な体験をしてください。',
          duration: 2000
        });
      }
    },
    getOrient () {
      return window.orientation === 0 || window.orientation === 180;
    },
    alreadyViews() {
      this.pageTip.show1 = false;
      localStorage.setItem('isFirstViews', '1')
    }
  }
};
</script>
<style scoped lang="less">
#videoPage {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  /deep/
  .van-nav-bar {
    width: 100%;
    height: 44px;
    background-color: #1b1c1d;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    .van-nav-bar__content {
      height: 100%;
    }

    &::after {
      border: none;
    }

    .van-nav-bar__title {
      flex: 1;
      max-width: calc(100% - 100px);
      font-size: 14px;
      font-weight: bold;
      line-height: 44px;
      color: #fff;
      text-align: left;
    }

    .van-nav-bar__left {
      left: 0;
    }

    .van-nav-bar__left,
    .van-nav-bar__right {
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      padding: 0 15px;
    }

    .back-arrow {
      width: 16px;
      height: 16px;
      background-image: url("~@/assets/images/icon_arrowLeft_01.png");
    }

    .right {
      display: flex;
      align-items: center;

      .icon {
        width: 24px;
        height: 24px;

        &.icon-menu {
          background-image: url("~@/assets/images/icon_menu_01.png");
        }
      }
    }
  }

  .container {
    position: relative;
    flex: 1;
    height: 0;
    font-size: 16px;

    .video-box {
      width: 100%;
      height: 100%;
    }

    .menu-list {
      position: absolute;
      top: 44px;
      right: 0;
      width: 210px;
      height: calc(100% - 44px);
      padding: 6px 5px;
      overflow-y: auto;
      background-color: rgba(0, 0, 0, 0.7);

      .list-item {
        padding: 6px 5px;

        .thumb {
          position: relative;
          height: 96px;

          &,
          .mask {
            overflow: hidden;
            border-radius: 8px;
          }

          .img {
            width: 100%;
            height: 100%;
          }

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);

            .item {
              position: absolute;
              left: 0;
              justify-content: space-between;
              width: 100%;

              &,
              .left,
              .right {
                display: flex;
                align-items: center;
              }

              &.item-1 {
                top: 0;
                padding: 4px 4px 4px 0;

                .icon-tag {
                  width: 18*1.4px;
                  height: 8*1.4px;
                }

                .icon-more {
                  width: 13*1.4px;
                  height: 14*1.4px;
                  background-image: url("~@/assets/images/icon_dot_01.png");
                }
              }

              &.item-2 {
                bottom: 0;
                padding: 5px 4px;
                font-size: 5*1.4px;

                .left {
                  .icon-eyes {
                    width: 8*1.4px;
                    height: 8*1.4px;
                    margin-right: 3*1.4px;
                    background-image: url("~@/assets/images/icon_eyesOpen_03.png");
                  }
                }
              }
            }

            .icon-play {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 23px;
              height: 23px;
              background-image: url("~@/assets/images/icon_arrowRight_03_.png");
              transform: translate(-50%, -50%);
            }
          }
        }

        .content {
          margin-top: 6px;
          font-size: 7*1.4px;
          line-height: 10*1.4px;

          .name {
            .text-overflow();

            height: 10*1.6px;
          }
        }

        &.style-1 {
          .thumb {
            .mask {
              .item {
                .icon-tag {
                  background-image: url("~@/assets/images/icon_tag_05.png");
                }
              }
            }
          }
        }

        &.style-2 {
          .thumb {
            .mask {
              .item {
                .icon-tag {
                  background-image: url("~@/assets/images/icon_tag_07.png");
                }
              }
            }
          }
        }
      }
    }
  }

  .page-tip {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
    background-color: rgba(0, 0, 0, 0.7);

    &.page-tip-1 {
      .box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 510px;

        .box-item {
          width: 247px;
          margin-bottom: 14px;

          &.box-item-1 {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .icon {
              margin-left: 7px;

              &.icon-tag {
                width: 42px;
                height: 16px;

                &.icon-1 {
                  background-image: url("~@/assets/images/icon_operate_10.png");
                }

                &.icon-2 {
                  background-image: url("~@/assets/images/icon_operate_09.png");
                }

                &.icon-3 {
                  background-image: url("~@/assets/images/icon_operate_11.png");
                }
              }

              &.icon-vr {
                width: 28px;
                height: 16px;
                background-image: url("~@/assets/images/icon_operate_08.png");
                opacity: 0.7;
              }

              &.icon-hand {
                width: 25.5px;
                height: 18px;
                background-image: url("~@/assets/images/icon_operate_05.png");
                opacity: 0.7;
              }
            }
          }
        }
      }

      .btn {
        width: 80px;
        height: 25px;
        margin-top: 22px;
        border: 1px solid #fff;
        border-radius: 8px;
      }
    }

    &.page-tip-2 {
      .icon {
        margin-bottom: 16px;

        &.icon-operate-1 {
          width: 153px;
          height: 153px;
          background-image: url("~@/assets/images/icon_operate_06.png");
        }

        &.icon-operate-2 {
          width: 112px;
          height: 102.5px;
          background-image: url("~@/assets/images/icon_operate_07.png");
        }
      }
    }
  }

  /* 选中分辨率弹窗 */
  #selectResolve {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .selectResolve-box{
      width: 100%;
      height: 182px;
      padding: 17px 20px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      .title {
        font-size: 20px;
        color: #fff;
        text-align: center;
      }

      .list {
        display: flex;
        justify-content: center;
        list-style: none;

        .list-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px 24px;
          color: #fff;
          text-align: center;

          &.active {
            color: #ffd34a !important;
          }

          .list-title {
            margin-bottom: 10px;
            font-size: 20px;
          }

          .list-content {
            margin-bottom: 15px;
            font-size: 14px;
            opacity: 0.7;
          }

          .img {
            width: 42px;
            height: 18px;
          }
        }
      }
    }

  }

  .popup-showPlay {
    width: 85%;
    height: 73%;
    border-radius: 0;

    .img {
      width: 100%;
      height: 202px;
    }

    .content {
      padding: 0 38px;
      margin-top: 16px;
      font-size: 26px;
      line-height: 20px;
      color: #fff;
      text-align: center;

      .title {
        margin-bottom: 12px;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
      }

      .info {
        height: 17px;
        margin-bottom: 18px;
        font-size: 13px;
        color: #fff;
        text-align: center;
        opacity: 0.7;
      }

      .btn {
        width: 100%;
        height: 42px;
        margin-top: 16px;
        border: none;
        border-radius: 8px;

        &.btn01 {
          background: linear-gradient(180deg, #36abf5 0%, #7acbff 100%);
        }

        &.btn02 {
          background: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
        }
      }
    }
  }
}

.menuList-enter-active,
.menuList-leave-active,
.topNav-enter-active,
.topNav-leave-active {
  transition: margin 0.2s;
}

.topNav-enter,
&.topNav-leave-to {
  margin-top: -44px;
}

.menuList-enter,
&.menuList-leave-to {
  margin-right: -210px;
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  #videoPage {
    .page-tip {
      width: 100vh;
      height: 100vw;
      transform: rotateZ(90deg);
      transform-origin: 50vw;
    }
  }
}
</style>